import { format as formatDate } from "date-fns";
import { mailmagNewContents, footer } from "./mailmag-newcontents"

const mailmagFrameA = function (data: any) {
	const bgcolor = '#eaeae6'
	const fontcolor = '#333333'
	const contents = mailmagNewContents({ contents: data.contents, fontcolor: fontcolor })
	const firstview = mailmagNewContents({ contents: data.firstview, fontcolor: fontcolor })

	const memberdate = data.date ? new Date(data.date) : new Date()

	return `<!DOCTYPE HTML PUBLIC "-//W3C//DTD HTML 4.01 Transitional//EN">
<html xmlns="http://www.w3.org/1999/xhtml">
<head>
	<meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
	<meta name="viewport" content="width=device-width"/>
	<title>ザ クラブ・メールマガジン</title>
	<style type="text/css">
		body {
			width: 100% !important;
			font-family:'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', 'Meiryo', 'Osaka', 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
			-webkit-text-size-adjust: 100%; 
			-ms-text-size-adjust: 100%;
			-webkit-font-smoothing: antialiased;
			word-break: break-all;
			margin: 0;
			padding: 0;
		}
		img { 
			line-height: 100%;
			outline: none;
			text-decoration: none;
			display:block;
		}
		a img {
			border: none;
		}
		table {
			border-collapse: collapse;
			color:${fontcolor};
			font-size:16px;
		}
		p{
			margin:0;
			padding:0;
		}

		a.btn01{
			display:inline-block;
			background-color:#867A4A;
			color:#fff;
			text-align:center;
			padding:10px 20px;
			text-decoration:none;
			min-width:150px;
			border-radius:100px;
		}
	</style>
</head>
<body>
<table border="0" cellspacing="0" cellpadding="0" width="100%" height="100%" bgcolor="#cccccc">
<tbody>
<tr>
<td align="center" valign="top">
<table width="600" bgcolor="${bgcolor}" border="0" cellspacing="0" cellpadding="0">
<tbody>
<!--メインここから-->
	<tr>
		<td height="15"></td>
	</tr>
	<tr>
		<td align="center" valign="center">
			<table border="0" cellspacing="0" cellpadding="0" width="100%" >
				<tbody>
					<tr>
						<td width="20"></td>
						<td width="230"></td>
						<td align="center" valign="center" width="100">
						<img src="https://images.microcms-assets.io/assets/319c608d4e9c445a9a79cf6d4f07a336/f81227ec7d8946279b5c5523ad045211/mailmag_logo_b.png" alt="THE Club ROYAL PARK HOTELS" border="0" width="90">
						</td>
						<td width="230"></td>
						<td width="20"></td>
					</tr>
				</tbody>
			</table>
		</td>
	</tr>
	<tr>
		<td height="15"></td>
	</tr>
	<tr>
		<td align="center" valign="top" bgcolor="#ffffff">
			${data.eyecatch?.url ? `<img src="${data.eyecatch.url}?w=1000" border="0" style="width:100%" width="100%" >` : ""}
			${data.firstview.length > 0 ? `<div style="position:relative;z-index:1;padding:0px;${data.eyecatch?.url ? 'margin-top:-50px;' : ''}">
				<table border="0" cellspacing="0" cellpadding="0" bgcolor="#ffffff" ${data.eyecatch?.url ? 'width="500"' : 'width="100%"'} >
					<tbody>
						${firstview}
					</tbody>
				</table>
			</div>`: ""}
		</td>
	</tr>
	${contents}
	<!--メインここまで-->
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</body>
</html>`
}

export default mailmagFrameA
