export const footer = (data: any) => {
  const bannerFlag = true;
  return `
  <tr class="footer_img">
		<td align="center" valign="bottom" style="background:#fff;">
			<img src="https://images.microcms-assets.io/assets/319c608d4e9c445a9a79cf6d4f07a336/01341d6c02f545d193c5a19555423188/mailmag_f.jpg" alt="ROYAL PARK HOTELS" width="600" border="0">
		</td>
	</tr>
  ${
    bannerFlag
      ? `
  <tr>
    <td height="20" bgcolor="#ffffff"></td>
  </tr>
  <tr>
    <td align="center" valign="center" bgcolor="#ffffff">
    <a href="https://apps.apple.com/jp/app/id6450347390?l=ja&ls=1&mt=8" target="_blank"><img src="https://images.microcms-assets.io/assets/319c608d4e9c445a9a79cf6d4f07a336/ecbc331abbbe418db03312d828e73452/apple.png" alt="" width="163" border="0" style="display:inline-block;"></a>
    <a href="https://play.google.com/store/apps/details?id=jp.co.royalparkhotels&hl=ja" target="_blank"><img src="https://images.microcms-assets.io/assets/319c608d4e9c445a9a79cf6d4f07a336/21b45ed50da7417dacae3da6e54ef185/google-play-badge.png" alt="" width="202" border="0" style="display:inline-block;"></a>
    </td>
  </tr>
  `
      : ``
  }
	<tr class="footer_txt" bgcolor="#ffffff">
		<td style="color:#572006; font-size:12px; padding: 20px 30px 20px 30px; border-bottom:2px dotted #572006;">
			・本メールマガジンに掲載されている画像は全てイメージです。<br>・本メールマガジンに掲載されている内容は予告無く変更、終了する場合がございます。<br>
			・本メールマガジンは送信専用として配信しております。<br>
			　ご返信いただいても お応えすることができませんのでご了承ください。<br>
      ${
        data.classification[0] === "ホテル情報" ||
        data.classification[0] === "お知らせ"
          ? `
      ・本メールマガジンは、各ホテルからの情報をご希望された方へ配信しております。<br>
      `
          : `
      ・本メールマガジンはポイントに関するお知らせの為、規約第17条に則りメールアドレスをご登録いただいた全ての会員さまへ毎月配信しております。<br />
      `
      }
			・本メールマガジンにお心当たりのない方は、誠にお手数でございますが、<br>
			　下記会員事務局までご連絡いただきますようお願い申し上げます。<br>
			・会員登録情報の確認、変更は<a href="https://www.royalparkhotels.co.jp/the-club/login/" target="_blank">会員サイト</a>ログイン後、<br>
			　会員メニューからお進みください。<br>
      ・処理中のポイントが反映されていない場合がございます。
		</td>
	</tr>
	<tr class="footer_contact" bgcolor="#ffffff">
		<td style="font-size:12px; padding: 20px 30px 20px 30px;color:#572006;">発行元<br>ザ クラブ・ロイヤルパークホテルズ会員事務局<br>Tel：0120-657-788（平日10:00〜12:30 / 13:30〜17:00）※年末年始は除く</td>
	</tr>
	<tr bgcolor="#ffffff">
		<td align="center" style="color:#572006; font-size:12px; padding: 30px 0px 0px 0px;">Japanese text only</td>
	</tr>
	<tr class="footer_copyright" bgcolor="#ffffff">
		<td align="center" style="color:#572006; font-size:12px; padding: 10px 0px 20px 0px;">&copy; Mitsubishi Estate Hotels & Resorts Co., Ltd. All rights reserved.</td>
	</tr>
  `;
};

export const mailmagNewContents = ({
  contents,
  fontcolor,
  padding = "padding-left:30px;padding-right:30px",
}: {
  contents: Array<any>;
  fontcolor: string;
  padding?: string;
}) => {
  const html: Array<any> = contents.map((item, index) => {
    if (item?.fieldId) {
      return item.fieldId === "section_title"
        ? sectionTitle({ data: item, fontcolor: fontcolor, padding: padding }) +
            marginBottom(item)
        : item.fieldId === "sentence"
        ? sentence({ data: item, padding: padding }) + marginBottom(item)
        : item.fieldId === "single_image"
        ? singleImage({ data: item, padding: padding }) + marginBottom(item)
        : item.fieldId === "link_button"
        ? linkButton({ data: item, padding: padding }) + marginBottom(item)
        : item.fieldId === "custom_link_button"
        ? customLinkButton({ data: item, padding: padding }) +
          marginBottom(item)
        : item.fieldId === "two_column"
        ? twoColumn({ data: item, fontcolor: fontcolor })
        : item.fieldId === "separate"
        ? separate({ data: item }) + marginBottom(item)
        : item.fieldId === "hr"
        ? hr({ data: item, fontcolor: fontcolor, padding: padding }) +
          marginBottom(item)
        : item.fieldId === "margin"
        ? margin({ data: item })
        : ``;
    }
  });
  return html.join("\n");
};

const sectionTitle = function ({ data, fontcolor, padding }: any) {
  const paddingwide = data.wide ? "" : padding;
  return `
<tr class="section_title">
	<td align="center" valign="top" width="100%" style="${paddingwide}">
    <table border="0" cellspacing="0" cellpadding="0" width="100%">
      <tbody>
        <tr>
	        <td align="${
            data.align ? "center" : "left"
          }" valign="center" width="100%" bgcolor="${
    data.bgcolor || "transparent"
  }" style="${
    data.bgcolor ? `padding-top:10px;padding-bottom:10px;${padding}` : ""
  };color:${data.fontcolor || fontcolor};font-size:${
    data.fontsize
  };font-weight:bold;">
            <div style="${
              data.underline
                ? `border-bottom:1px solid ${
                    data.fontcolor || fontcolor
                  };padding-bottom:5px;`
                : ""
            }">${data.title && data.title.replace(/\n/g, "<br />")}</div>
          </td>
        </tr>
      </tbody>
    </table>

	</td>
</tr>
  `;
};

const sentence = function ({ data, padding }: any) {
  const text = data.text && data.text.replace(/<br>/g, "<br>\n");
  return `
<tr class="sentence">
	<td align="${
    data.align ? "center" : "left"
  }" valign="top" width="100%" style="${padding};">
    <div style="line-height:1.5;font-size:${data.fontsize}">${text}</div>
	</td>
</tr>
  `;
};

const singleImage = function ({ data, padding }: any) {
  const paddingwide = data.wide ? "" : padding;
  return `
<tr class="single_image">
	<td align="center" valign="top" width="100%" style="${paddingwide}">
  ${data.href ? `<a href="${data.href}" target="_blank">` : ""}
    <img src="${data.image.url}?w=1000" alt="" border="0" width="100%">
  ${data.href ? `</a>` : ""}
  </td>
</tr>
  `;
};

const linkButton = function ({ data, padding }: any) {
  return `
<tr class="link_button">
	<td align="center" valign="center" width="100%" style="${padding};">
				<a href="${data.href}" target="_blank" class="btn01" style="font-size:${data.fontsize}">
					${data.label}
				</a>
	</td>
</tr>
  `;
};
const customLinkButton = function ({ data, padding }: any) {
  let fontsize = `font-size: ${data.fontsize};`;
  let wide = data.width
    ? data.width == "100%"
      ? `display:block;`
      : `display:inline-block;width:${data.width};`
    : "display:inline-block;";
  let fontcolor = `color:${data.fontcolor};`;
  let bgcolor = `background-color:${data.bgcolor};`;
  let border = data.border ? `border:2px solid ${data.fontcolor}` : "";
  let radius = data.radius ? `border-radius:100px;` : "";
  return `
<tr class="link_button">
	<td align="center" valign="center" width="100%" style="${padding};">
				<a href="${data.href}" target="_blank" class="btn01" style="padding:10px 20px;text-decoration:none;text-align:center;${fontsize}${wide}${fontcolor}${bgcolor}${radius}${border}">
					${data.label}
				</a>
	</td>
</tr>
  `;
};

const twoColumn = function ({ data, fontcolor }: any) {
  return `
<tr class="two_column">
  <td align="center" valign="center" width="100%" style="padding:0px 0px;">
    <table border="0" cellspacing="0" cellpadding="0" width="100%">
    <tr>
      <td align="left" valign="${
        data.left_valign ? "center" : "top"
      }" width="50%">
        <table border="0" cellspacing="0" cellpadding="0" width="100%">
        ${mailmagNewContents({
          contents: data.left,
          fontcolor: fontcolor,
          padding: "padding-left:30px;padding-right:10px",
        })}
        </table>
      </td>
      <td align="left" valign="${
        data.right_valign ? "center" : "top"
      }" width="50%">
        <table border="0" cellspacing="0" cellpadding="0" width="100%">
        ${mailmagNewContents({
          contents: data.right,
          fontcolor: fontcolor,
          padding: "padding-left:10px;padding-right:30px",
        })}
        </table>
      </td>
    </tr>
    </table>
  </td>
</tr>
  `;
};

const separate = function ({ data }: any) {
  const color = data.color || "#cccccc";
  return `
<tr class="hr">
<td height="30" bgcolor="${color}"></td>
</tr>
  `;
};

const hr = function ({ data, fontcolor, padding }: any) {
  const type = data.dotted ? "dashed" : "solid";
  return `
<tr class="hr">
<td height="1" style="${padding}">
<div style="border-bottom:1px ${type} ${fontcolor}"></div>
</td>
</tr>
  `;
};

const marginBottom = function (data: any) {
  return !data.no_mb
    ? `
<tr class="mb">
<td height="30"></td>
</tr>
  `
    : "";
};

const margin = function ({ data }: any) {
  const size = data.small ? "15" : "30";
  return `
<tr class="mb">
<td height="${size}"></td>
</tr>
  `;
};
